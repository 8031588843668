/* Navbar.css */
.navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
     /* Set background color to black */
    color: #fff; /* Set text color to white */
    z-index: 1000; /* Ensure navbar stays on top of other content */
    background-color: #042c44;
  }
  
  .logo {
    font-size: 24px;
  }
  
  .menu-container {
    display: flex;
  }
  
  .menu {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
    font-size: 1.4rem;
  }
  
  .menu li {
    margin-left: 20px;
    cursor: pointer;
    color: #fff; /* Set text color of menu items to white */
  }
  
  .hamburger-menu {
    display: none; /* Hide the hamburger menu by default */
    flex-direction: column;
    justify-content: space-between;
    width: 30px;
    height: 20px;
    cursor: pointer;
  }
  
  .line {
    width: 100%;
    height: 3px;
    background-color: #fff; /* Set color of hamburger menu icon to white */
  }
  
  /* Show the menu on smaller screens */
  @media screen and (max-width: 768px) {
    .navbar{
      background-color: #042c44;
    }
    .menu-container {
      position: fixed;
      top: 60px; /* Adjust as needed */
      right: 0;
      background-color: #042c44; /* Set background color to black */
      height: 100%;
      width: 200px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 20px;
      transition: transform 0.3s ease-in-out;
      transform: translateX(100%);
      box-shadow: 0 2px 5px rgba(255, 255, 255, 0.2); /* Add shadow to menu container */
      background-color: #042c44
    }
  
    .menu-container.show {
      transform: translateX(0);
    }
  
    .menu {
      flex-direction: column;
      margin-top: 20px;
      background-color: #042c44;
    }
  
    .menu li {
      margin: 10px 0;
    }
  
    .hamburger-menu {
      display: flex;
    }
  
    .line {
      background-color: #fff; /* Set color of hamburger menu icon to white */
    }
  }
  #bu{
    background-color: aqua;
    color: #000;
  }
  