@tailwind base;
@tailwind components;
@tailwind utilities;

.progressAnimation {
  animation: progressBarAnimation 20s linear infinite;
}

@keyframes progressBarAnimation {
  0%,
  100% {
    width: 0;
  }
  50% {
    width: 100%;
  }
}
