.faq-section {
    width: 100%;
  }
  
  .faq {
    background-color: #f0f0f0;
    border-radius: 8px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .faq:hover {
    background-color: #e0e0e0;
  }
  
  .question {
    font-weight: bold;
    padding: 10px;
  }
  
  .answer {
    padding: 0 10px 10px 10px;
    display: none;
  }
  
  .faq.open .answer {
    display: block;
  }
  .question {
    font-weight: bold;
    padding: 30px;
    padding-left: 50px; /* Adding padding to the left side */
  }
  .answer{
    font-weight: bold;
    
    padding-left: 50px; /* Adding padding to the left side */
  }
  