/* Countdown.css */
.countdown-container {
  text-align: center;
  margin-top: 50px;
}

.countdown {
  display: flex;
  justify-content: center;
  align-items: center;
}

.countdown-item {
  margin: 0 10px;
}

.countdown-value {
  font-size: 36px;
  font-weight: bold;
  color: #fff; /* Set text color to white */
  background-color: #333; /* Set background color */
  padding: 10px 20px; /* Add padding for better appearance */
  border-radius: 5px; /* Add border-radius for rounded corners */
}

.countdown-label {
  font-size: 14px;
  text-transform: uppercase;
  color: #777;
}

.countdown-container h2 {
  font-size: 24px;
  color: #fff; /* Set text color to white */
}

/* Gradient background */

